import { cn } from "~/utilities/cn";

export type ChipProps = {
  children?: React.ReactNode;
  label: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onDelete?: () => void;
  className?: string;
  theme?: "dark" | "light";
  variant?: "primary" | "secondary";
};

const Chip: React.FC<ChipProps> = ({
  children,
  label,
  leftIcon,
  rightIcon,
  className,
  theme = "dark",
  variant = "primary",
}) => {
  const variantStyles = {
    primary: "py-1 px-3 rounded",
    secondary: "py-2 px-3 rounded-xl",
  };
  return (
    <div className={cn(variantStyles[variant], className)}>
      {leftIcon && <div className="chip-icon mr-2">{leftIcon}</div>}
      {label && (
        <div
          className={cn(
            theme === "dark" ? "text-background" : "text-color",
            "text-sm"
          )}
        >
          {label}
        </div>
      )}
      {rightIcon && <div className="chip-icon ml-2">{rightIcon}</div>}
      {children}
    </div>
  );
};

export default Chip;
