type InputType = number | null | undefined | "";

// Helper function to round up and format the value
const formatValue = (value: number): string => {
  if (!value || isNaN(value)) return ""; // Check if the value is NaN or falsy
  let roundedValue;
  if (value < 1) {
    // If the value is less than 1, round to one decimal place
    roundedValue = Math.round(value * 10) / 10;
  } else {
    roundedValue = Math.ceil(value); // Round up if the value is 1 or more
  }
  return roundedValue >= 1000000
    ? `$${(roundedValue / 1000000).toFixed(3)}M`
    : `$${roundedValue.toLocaleString("en-SG")}`;
};

const processPrices = (
  total: InputType,
  available: InputType,
  minValue: InputType,
  maxValue: InputType
): string => {
  const totalNumber = typeof total === "number" ? total : 0;
  const minNumber = typeof minValue === "number" ? minValue : 0;
  const maxNumber = typeof maxValue === "number" ? maxValue : 0;

  if (
    !totalNumber ||
    (typeof minValue !== "number" && typeof maxValue !== "number")
  ) {
    return "enquire_for_price";
  }

  if (minNumber > 0 && maxNumber > 0) {
    return minNumber === maxNumber
      ? formatValue(maxNumber)
      : `${formatValue(minNumber)}  -  ${formatValue(maxNumber)}`;
  }

  return minNumber > 0 ? formatValue(minNumber) : formatValue(maxNumber);
};

export default processPrices;
