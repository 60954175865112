import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "~/components/atoms/Button";
import Typo from "~/components/atoms/Typo";
import { TUnitType } from "~/entities/unitType";
import { cn } from "~/utilities/cn";
import { EBgColor, ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import processPrices from "~/utilities/helpers/processPrices";
import processSizes from "~/utilities/helpers/processSizes";

export interface UnitTypeTabsProps {
  unitTypes: TUnitType[];
  layout?: "vertical" | "horizontal";
}

const UnitTypeTabs: React.FC<UnitTypeTabsProps> = ({ unitTypes, layout }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);

  const setActiveUnitType = useCallback(() => {
    if (isEmpty(unitTypes)) return;
    const activeIndex = unitTypes.findIndex(
      (unitType) => unitType.active === true
    );
    setActiveTab(activeIndex === -1 ? 0 : activeIndex);
  }, [unitTypes]);

  useEffect(() => {
    setActiveUnitType();
  }, [unitTypes, setActiveUnitType]);

  // Memoize the selected size to avoid unnecessary updates
  const selectedSize = useMemo(() => {
    if (isEmpty(unitTypes) || isEmpty(unitTypes?.at(activeTab))) {
      return { minSize: "", maxSize: "" };
    }
    return {
      minSize: unitTypes?.at(activeTab)?.minArea?.toString() || "",
      maxSize: unitTypes?.at(activeTab)?.maxArea?.toString() || "",
    };
  }, [unitTypes, activeTab]);

  // Memoize the price to avoid unnecessary updates
  const price = useMemo(() => {
    if (isEmpty(unitTypes) || isEmpty(unitTypes?.at(activeTab))) {
      return "enquire_for_price";
    }
    return processPrices(
      unitTypes?.at(activeTab)?.totalUnits,
      unitTypes?.at(activeTab)?.availableUnits,
      unitTypes?.at(activeTab)?.minPrice,
      unitTypes?.at(activeTab)?.maxPrice
    );
  }, [unitTypes, activeTab]);

  const handleChangeTab = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setActiveTab(index);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="scroll scroll-x no-scrollbar flex min-h-6 flex-row flex-nowrap items-center justify-start gap-2 overflow-x-auto">
        {unitTypes?.map((unitType, index) => (
          <Button
            key={unitType.id + index}
            size="small"
            variant={activeTab === index ? "default" : "text"}
            onClick={(event) => {
              event.stopPropagation();
              handleChangeTab(event, index);
            }}
            className={cn(
              "hover:opacity-100",
              activeTab === index
                ? "bg-color text-cardButtonActiveText hover:bg-color"
                : "hover:text-cardButtonActiveTextHover text-cardButtonText"
            )}
          >
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={
                activeTab === index
                  ? ETypoColor.CARD_BUTTON_ACTIVE
                  : ETypoColor.CARD_BUTTON
              }
            >
              {unitType.title}
            </Typo>
          </Button>
        ))}
      </div>
      <div
        className={cn("divider m-0 h-[1px]", EBgColor.CARD_PROJECT_DIVIDER)}
      ></div>
      <div className="flex gap-3">
        <div className="flex grow-0 flex-col gap-2">
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.SUB_TITLE_16}
            color={ETypoColor.CARD_UNIT_TYPE_HEADING}
          >
            {t("project_card.price")}:
          </Typo>
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.SUB_TITLE_16}
            color={ETypoColor.CARD_UNIT_TYPE_HEADING}
          >
            {t("size")}
          </Typo>
        </div>
        <div className="flex grow flex-col gap-2">
          <div className="mb-auto flex gap-x-3 gap-y-1">
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={
                price === "sold_out"
                  ? ETypoColor.CARD_SOLD_OUT
                  : ETypoColor.CARD
              }
            >
              {t(price)}
            </Typo>
          </div>
          <div className="mt-auto flex gap-x-3 gap-y-1">
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.CARD}
            >
              {t(processSizes(selectedSize.minSize, selectedSize.maxSize))}
            </Typo>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitTypeTabs;
