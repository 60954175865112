type InputType = string | number | null | undefined;

// Function to process size values
const processSizes = (minSize: InputType, maxSize: InputType): string => {
  const minNumber =
    typeof minSize === "number" ? minSize : parseFloat(minSize as string) || 0;
  const maxNumber =
    typeof maxSize === "number" ? maxSize : parseFloat(maxSize as string) || 0;

  if (minNumber > 0 && maxNumber > 0) {
    if (minNumber === maxNumber) {
      return `${maxNumber} sqft`;
    } else {
      return `${minNumber}  -  ${maxNumber} sqft`;
    }
  }

  if (minNumber > 0) {
    return `${minNumber} sqft`;
  }

  if (maxNumber > 0) {
    return `${maxNumber} sqft`;
  }

  return "enquire_for_size";
};

export default processSizes;
