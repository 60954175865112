import { useTranslation } from "react-i18next";

import ButtonResize from "../ButtonResize";

import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import Typo from "~/components/atoms/Typo";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface ButtonCompareProps {
  variant?: "long" | "short";
  classNameButton?: string;
  isAdded?: boolean;
  addComparedProject: () => void;
}

const ButtonCompare: React.FC<ButtonCompareProps> = ({
  variant = "long",
  classNameButton = "",
  isAdded = false,
  addComparedProject,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isAdded ? (
        <Button
          size="default"
          variant="success"
          className={classNameButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (typeof addComparedProject === "function") addComparedProject();
          }}
        >
          <div>
            <Icon TypeIcon={EIcon.SuccessIcon} height={20} width={20} />
          </div>
          <Typo
            tag={ETypoTag.P}
            variant={ETypoVariant.SUB_TITLE_14}
            color={ETypoColor.WHITE}
          >
            {t("added")}
          </Typo>
        </Button>
      ) : (
        <ButtonResize
          className={classNameButton}
          variant={variant}
          onClick={addComparedProject}
        />
      )}
    </>
  );
};

export default ButtonCompare;
